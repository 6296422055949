export function usePageVisibilityHandlers({ onPageShow, onPageHide }: { onPageShow?: () => void; onPageHide?: () => void }) {
  const visibility = useDocumentVisibility();
  watch(visibility, (currentVisible: string) => {
    // 页面显示
    if (currentVisible === "visible") {
      if (typeof onPageShow === "function") {
        onPageShow();
      }
    } else if (currentVisible === "hidden") {
      // 页面隐藏
      if (typeof onPageHide === "function") {
        onPageHide();
      }
    }
  });
}
